.savings-accounts-form-summary-container {
  display: flex;
  // flex-direction: column;
  justify-content: space-around;
  width: 1100px;
  // padding-left: 10%;
  // margin-top: 5%;
  // margin-left: 5%;
  // margin-bottom: 5%;

  h2 {
    margin: 10px 0;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 8%;
    // width: 400px;
  }

  .savings-account-info-summary {
    height: 30%;
    margin-top: 3%;
    margin-right: 3%;
    display: flex;
    display: block;
  }
}