.investment-info-summary {
  height: 30%;
  margin-top: 3%;
  margin-right: 5%;
  // display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  align-content: space-evenly;
}

.savings-account-info-summary {
  height: 30%;
  margin-top: 3%;
  margin-right: 10%;
  padding-left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-evenly;
}