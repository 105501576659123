.investments-form-summary-container {
  display: flex;
  justify-content: space-evenly;
  width: 1225px;
  // margin-left: 5%;

  h2 {
    margin: 5px 0;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 8%;
    // width: 300px;
  }

  .investment-info-summary {
    height: 30%;
    margin-top: 3%;
    margin-right: 5%;
    display: flex;
    // justify-content: space-around;
    display: block;
  }
}